import {
  PLAYER_OVERVIEW_SETTINGS_FAILURE,
  PLAYER_OVERVIEW_SETTINGS_SUCCESS,
  USER_CUSTOM_SETTING_UPDATE_REQUEST,
  USER_PREFERENCES_RETRIEVE_FAILURE,
  USER_PREFERENCES_RETRIEVE_SUCCESS,
} from "../constants";
import AccountInfo from "../../Pages/Players/individual-player/player-info/account-info";
import PersonalInfo from "../../Pages/Players/individual-player/player-info/personal-info";
import PlayerNotes from "../../Pages/Players/individual-player/communications/player-notes/player-notes";
import CommunicationSettings from "../../Pages/Players/individual-player/communications/communication-settings";
import LoginHistory from "../../Pages/Players/individual-player/player-info/login-history/login-history";
import IndividualPlayerGroups from "../../Pages/Players/individual-player/player-info/player-groups/individual-player-groups";
import CommunicationHistory from "../../Pages/Players/individual-player/communications/communication-history/communication-history";
import ServiceParameterGroups from "../../Pages/Players/individual-player/player-info/service-parameter-groups/service-parameter-groups";
import DirectNotificationHistory from "../../Pages/Players/individual-player/communications/direct-notifications/direct-notification-history";
import SelfExclusion from "../../Pages/Players/individual-player/responsible-game/self-exclusion";
import FinancialLimits from "../../Pages/Players/individual-player/responsible-game/financial-limits";
import ClosingAccountStatus from "../../Pages/Players/individual-player/player-info/closing-account-status";
import KYCStatus from "../../Pages/Players/individual-player/player-info/kyc-status/kyc-status";
import playerFavorites from "../../Pages/Players/individual-player/player-info/player-favorites/player-favorites";
import ClubCard from "../../Pages/Players/individual-player/management/club-card";
import ContactVerification from "../../Pages/Players/individual-player/management/contact-verification";
import SensitiveVipManagement from "../../Pages/Players/individual-player/management/sensitive-vip-management";
import PlayerPreferences from "../../Pages/Players/individual-player/management/player-preferences";
import PepDetails from "../../Pages/Players/individual-player/player-info/pep-details";
import LatestRegulatorStatus from "../../Pages/Players/individual-player/management/latest-regulator-status";
import loyaltyDetailsWidget from "../../Pages/Players/individual-player/rewards/loyalty-details-widget";

const defaultSettings = [
  {
    name: "closeStatus",
    customSettingsId: 14,
    defaultCollapse: false,
    hidden: (playerInfo) => {
      return (
        playerInfo == null ||
        playerInfo.closedPlayerAccount == null ||
        playerInfo.closedPlayerAccount.closingStatus === 0
      );
    },
  },
  {
    name: "accountInfo",
    customSettingsId: 1,
    defaultCollapse: false,
  },
  {
    name: "playerGroups",
    customSettingsId: 7,
    link: "/player-groups",
    permissionId: 388,
    hideAll: true,
  },
  {
    name: "selfExclusion",
    customSettingsId: 15,
    link: "/self-exclusion",
    pam: true,
    permissionId: 614,
    fromSideMenu: false,
    hideAll: true,
  },
  {
    name: "financialLimits",
    customSettingsId: 16,
    link: "/financial-limits",
    pam: true,
    permissionId: 194,
    fromSideMenu: false,
  },
  {
    name: "personalInfo",
    customSettingsId: 2,
    gdpr: true,
    link: "/edit-player",
    permissionId: 196,
    fromSideMenu: false,
  },
  {
    name: "contactVerification",
    customSettingsId: 20,
    link: "/contact-verification",
    defaultCollapse: false,
    permissionId: 666,
    fromSideMenu: false,
  },
  {
    name: "lastAuthenticatedSession",
    customSettingsId: 5,
    link: "/login-history",
    pam: true,
    permissionId: 212,
    hideAll: true,
    fromSideMenu: false,
  },
  {
    name: "directNotificationHistory",
    customSettingsId: 13,
    link: "/direct-notification-history",
    gdpr: true,
    pam: true,
    permissionId: 409,
    hideAll: true,
    fromSideMenu: false,
  },
  {
    name: "serviceParameterGroups",
    customSettingsId: 9,
    link: "/service-parameter-groups",
    gdpr: true,
    pam: true,
    permissionId: 224,
    hideAll: true,
    fromSideMenu: false,
  },
  {
    name: "lastPlayerNote",
    customSettingsId: 3,
    link: "/player-notes",
    pam: true,
    permissionId: 222,
    hideAll: true,
    fromSideMenu: false,
  },
  {
    name: "loyaltyDetails",
    customSettingsId: 6,
    link: "/loyalty-details",
    hideAll: true,
    permissionId: 316,
    fromSideMenu: false,
  },
  {
    name: "communicationPreferences",
    customSettingsId: 4,
    linkId: 5023,
    pam: true,
    permissionId: 5023,
    hideAll: true,
  },
  {
    name: "communicationHistory",
    customSettingsId: 8,
    link: "/communication-history",
    gdpr: true,
    permissionId: 400,
    hideAll: true,
    fromSideMenu: false,
  },
  {
    name: "kycStatus",
    customSettingsId: 17,
    link: "/kyc-status",
    permissionId: 617,
    fromSideMenu: false,
  },
  {
    name: "playerFavorites",
    customSettingsId: 18,
    link: "/player-favorite",
    permissionId: 627,
    hideAll: true,
    fromSideMenu: false,
  },
  {
    name: "clubCard",
    customSettingsId: 19,
    link: "/club-card",
    permissionId: 621,
    hideAll: true,
    fromSideMenu: false,
  },
  {
    name: "sensitiveVIP",
    customSettingsId: 21,
    link: "/sensitive-vip-management",
    permissionId: 671,
    hideAll: true,
    fromSideMenu: false,
  },
  {
    name: "playerPreferences",
    customSettingsId: 22,
    link: "/player-preferences",
    permissionId: 685,
    hideAll: true,
    fromSideMenu: false,
  },
  {
    name: "latestRegulatorStatus",
    customSettingsId: 23,
    hideAll: true,
    fromSideMenu: false,
  },
  {
    name: "pepDetails",
    customSettingsId: 10000,
    link: "/pep-details",
    permissionId: 10050,
    hideAll: true,
    fromSideMenu: false,
  },
];

const getSessionStoredSettings = () => {
  let settings = sessionStorage.getItem("settingsPlayerOverview");
  if (settings != null) {
    settings = JSON.parse(settings);
  }
  if (settings != null) {
    defaultSettings.forEach((it) => {
      settings[it.customSettingsId] = {
        ...it,
        ...settings[it.customSettingsId],
      };
    });
  }
  return (
    settings ||
    defaultSettings.reduce((acc, item) => {
      acc[item.customSettingsId] = item;
      return acc;
    }, {})
  );
};

const updateSessionStoredSettings = (newSettings) => {
  sessionStorage.setItem("settingsPlayerOverview", JSON.stringify(newSettings));
};

const initialState = {
  userPreferences: {
    id: null,
    values: [],
  },
  userCustomSettings: getSessionStoredSettings(),
  userCustomSettingsLayout: {
    standard: [
      [
        14,
        1,
        7,
        15,
        16,
        3,
        6,
        2,
        10000,
        20,
        17,
        5,
        13,
        9,
        22,
        4,
        8,
        18,
        19,
        21,
        23,
      ],
    ],
    mid: [
      [14, 1, 7, 15, 16, 3, 6, 21, 23],
      [2, 10000, 20, 17, 5, 13, 9, 22, 4, 8, 18, 19],
    ],
    large: [
      [14, 1, 7, 15, 16, 21],
      [2, 10000, 20, 17, 5, 13, 9, 18, 19],
      [3, 6, 22, 4, 8, 23],
    ],
  },
};

export const widgets = {
  1: AccountInfo,
  2: PersonalInfo,
  3: PlayerNotes,
  4: CommunicationSettings,
  5: LoginHistory,
  6: loyaltyDetailsWidget,
  7: IndividualPlayerGroups,
  8: CommunicationHistory,
  9: ServiceParameterGroups,
  22: PlayerPreferences,
  20: ContactVerification,
  13: DirectNotificationHistory,
  14: ClosingAccountStatus,
  15: SelfExclusion,
  16: FinancialLimits,
  17: KYCStatus,
  18: playerFavorites,
  19: ClubCard,
  21: SensitiveVipManagement,
  23: LatestRegulatorStatus,
  10000: PepDetails,
};

const UserPreferencesReducer = (state = initialState, action) => {
  switch (action.type) {
    case USER_PREFERENCES_RETRIEVE_SUCCESS: {
      return {
        ...state,
        userPreferences: { ...action.payload },
      };
    }

    case USER_PREFERENCES_RETRIEVE_FAILURE: {
      return {
        ...state,
        userPreferences: { id: null, values: [] },
      };
    }

    case USER_CUSTOM_SETTING_UPDATE_REQUEST: {
      const { setting, id } = action;
      let originalSetting = state.userCustomSettings[id];
      if (originalSetting == null) {
        originalSetting = { customSettingsId: id };
      }
      const newSetting = { ...originalSetting, ...setting };
      const newSettings = { ...state.userCustomSettings, [id]: newSetting };
      updateSessionStoredSettings(newSettings);
      return {
        ...state,
        userCustomSettings: newSettings,
      };
    }
    case PLAYER_OVERVIEW_SETTINGS_SUCCESS: {
      return {
        ...state,
        overviewSettings: action.payload,
      };
    }

    case PLAYER_OVERVIEW_SETTINGS_FAILURE: {
      return {
        ...state,
        overviewSettings: [],
      };
    }
    default:
      return state;
  }
};

export default UserPreferencesReducer;
